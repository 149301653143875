import * as React from 'react'


export default function (props) {
  return (
    <div
      style={{
        backgroundColor: '#eaeaea',
        paddingTop: '3em',
        paddingBottom: '2em',
        textAlign: 'center',
      }}
    >
      <div className='page-heading'>
        <div className='container'>
          <div className='row'>
            <h1 className='center'>
              {props.text.toUpperCase()}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}
