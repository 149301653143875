import React, {useEffect, useState} from 'react'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import {Link} from 'gatsby'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'


const resourceItems = [
	{
		sectionTitle: 'Finance',
		sectionItems: [
			{
				label: 'TQA NFA Invoice Template',
				location: '#',
			},
			{
				label: 'LWDB Planning Worksheet',
				location: '#',
			},
		]
	},
	{
		sectionTitle: 'Operations',
		sectionItems: [
			{
				label: 'Participant Eligibility Checklist',
				location: '#',
			},
			{
				label: 'TQA LWDB Resource Guidebook',
				location: '#',
			},
			{
				label: 'Example of Work Process Schedule',
				location: '#',
			},
			{
				label: 'Data Entry Guide',
				location: '#',
			},
		]
	},
	{
		sectionTitle: 'Marketing',
		sectionItems: [
			{
				label: 'Social Media Templates: LinkedIn',
				location: '#',
			},
			{
				label: 'Social Media Templates: Facebook',
				location: '#',
			},
			{
				label: 'Social Media Templates: Twitter',
				location: '#',
			},
			{
				label: 'TQA Fonts',
				location: '#',
			},
			{
				label: 'TQA Logos',
				location: '#',
			},
		]
	},
]

export default function StaffResourceMaterials() {
  const [global, setGlobal] = useState(sessionManager.getState())
  const dispatch = sessionManager.dispatch


  useEffect(function () {
    return sessionManager.subscribe(function () {
      setGlobal(sessionManager.getState())
    })
  }, [])


	return (
    <main>
      <title>Staff Resource Materials</title>

			<NavBar global={global} dispatch={dispatch} />
			<PageHeader
				text={`Welcome, ${'USER_FIRST_NAME'}!`}
			/>

			<div>
				{resourceItems.map(({sectionTitle, sectionItems}, i) => (
					<div key={sectionTitle + i}>
						<div>{sectionTitle}</div>
						<ul>
							{sectionItems.map(({label, location}, i) => (
								<li key={location + i}>
									<Link to={location} >
										{label}
									</Link>
								</li>
							))}
						</ul>
					</div>
				))}
			</div>

		</main>
	)
}
